import React, { useEffect, useState } from "react"
import LayoutFirebase from "../src/LayoutFirebase"
import dynamic from "next/dynamic"
import firebase, { boards, loadBoardImageUrl, loadUserPublicDetailsFromUid } from "../src/FirebaseApp"

import { useRouter } from "next/router"

import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { useTranslation } from "next-i18next"
import BoardHead from "../src/BoardHead"
import ProductList from "../src/ProductList"
import TotalFooter from "../src/TotalFooter"
import Loading from "../src/Loading"
import MetaTags  from "../src/MetaTags"

function App({ board: boardJSON, user: userJSON }) {
  const router = useRouter()
  if (router.isFallback) {
    return <Loading minHeightScreen />
  }

  const [board, setBoard] = useState(boardJSON ? JSON.parse(boardJSON) : null)
  const user = userJSON ? JSON.parse(userJSON) : { displayName: '', username: '' }
  useEffect(() => {
    const refetchBoard = async () => {
      setBoard(await fetchBoard(board?.id))
    }

    if (localStorage.getItem("userPublic")) {
      refetchBoard()
    }
  }, [])

  const { t } = useTranslation()
  const products = Object.values(board.products)
  board.title = board.title || t("board.title", { count: products.length })
  board.description =
    board.description || t("board.description", { count: products.length })

  return (
    <>
      <MetaTags
        title={board.title}
        description={board.description}
        image={board.imageBase64 ? `https://gh-imgproxy-docker.onrender.com/preset:sharp/resize:fill:980:768:0/${board.imageBase64}` : ''}
        created={board.created}
        authorName={user?.displayName}
        authorUrl={user?.username ? `${process.env.NEXT_PUBLIC_SITEURL}/p/${user?.username}` : undefined }
      />
      <LayoutFirebase>
        <div className="max-w-5xl mx-auto sm:mt-8">
          <BoardHead board={board} />
          <ProductList
            products={products}
            InnerButton={function Button() {return <>{t("open")}</>}}
          />
          <TotalFooter products={products} />
        </div>
      </LayoutFirebase>
    </>
  )
}

export default App

async function fetchBoard(boardId) {
  const boardsRef = await boards()
  const boardRes = await boardsRef.doc(boardId).get()
  if (!boardRes.exists) return null
  const imageUrl = await loadBoardImageUrl(boardId)
  const data = boardRes.data()
  const imageBase64 = imageUrl ? Buffer.from(imageUrl).toString("base64") : null
  const board = { id: boardId, ...data, image: imageUrl, imageBase64 }
  board.created = board.created.toDate().toISOString()
  return board
}

export const getStaticProps = async ({ locale, params }) => {
  const { boardId } = params
  const board = await fetchBoard(boardId)
  
  if (!board) {
    return {
      notFound: true,
    }
  }
  
  const user = await loadUserPublicDetailsFromUid(board.user) || null
  return {
    props: {
      ...(await serverSideTranslations(locale)),
      board: JSON.stringify(board),
      user: JSON.stringify(user),
    },
    revalidate: 1,
  }
}

export async function getStaticPaths() {
  const boardsRef = await boards()
  const boardsRes = await boardsRef
    .where("status", "==", "public")
    .limit(process.env.STATIC_LIMIT)
    .get()
  const paths = boardsRes.docs.map(doc => ({ params: { boardId: doc.id } }))

  return {
    paths,
    fallback: true,
  }
}
